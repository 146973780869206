import React, { Component } from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "gatsby";
import logo from "../images/logo.webp";
import Scroll from "react-scroll";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
// import FacebookChat from "./common/FacebookChat";

import config from '../utils/siteConfig';

export default class NavBar extends Component {
    componentDidMount() {
        if (config.focusItem) {
            const script = document.createElement("script");
            script.src = config.focusUrl;
            script.async = true;
            document.body.appendChild(script);
        }
        if (config.chatIntegration) {
            const script = document.createElement("script");
            script.src = config.chatUrl;
            script.async = true;
            document.body.appendChild(script);
        }
    }
      
    componentWillUnmount() {
        // console.log("will unmount navbar")
        if (config.focusItem) {
            let scriptToremove = config.focusUrl;
            let allsuspects=document.getElementsByTagName("script");
            console.log("---allsuspects")
            console.log(allsuspects)
            for (let i=allsuspects.length; i>=0; i--){
                if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null 
                    && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`)!== -1 ){
                        console.log("remove focusItem")
                        console.log(allsuspects[i])
                        allsuspects[i].parentNode.removeChild(allsuspects[i])
                }
            }
        }
        if (config.chatIntegration) {
            let scriptToremove = config.chatUrl;
            let allsuspects=document.getElementsByTagName("script");
            for (let i=allsuspects.length; i>=0; i--){
                if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null 
                    && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`)!== -1 ){
                        console.log("remove chat")
                        allsuspects[i].parentNode.removeChild(allsuspects[i])
                }
            }
        }
    }

    render() {
        const ScrollLink = Scroll.Link;
        return (
            <>
                {/* <FacebookChat /> */}
                {/* <MessengerCustomerChat 
                    pageId="107280000936609"
                    appId="964774233994250"
                    version="8.0"
                /> */}
                <div className="nav">
                    <Container className="nav__container">
                        <Row className="nav__row">
                            <Col className="nav__col-left" md={3} lg={5} xl={4}>
                                <Link className="nav__logo" to="/">
                                    <Image src={logo} fluid={true} />
                                </Link>
                            </Col>
                            <Col className="nav__col-right" md={9} lg={7} xl={8}>
                                <Link className="nav__item" to="/">
                                    Home
                                </Link>
                                <div className="nav__item">
                                    <ul className="nav__item-service">
                                        <li className="dropdown">
                                            <a href="#" className="trigger-drop">
                                                Services<i className="arrow"></i>
                                            </a>
                                            <ul className="drop">
                                                <li className="dropdown">
                                                    <Link to="/DigitalTransformation/">
                                                        Digital Transformation
                                                    </Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/Infrastructure">
                                                        Infrastructure
                                                    </Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/Security">
                                                        Security
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <Link className="nav__item" to="/Store">
                                    Store
                                </Link>
                                <Link className="nav__item" to="/blog">
                                    Blog
                                </Link>
                                <Link className="nav__item" to="/About">
                                    About
                                </Link>
                                <Link className="nav__item" to="/Contact">
                                    Contact
                                </Link>
                                {/* <ScrollLink
                                    to="contact"
                                    smooth={true}
                                    duration={1000}
                                    className="nav__item"
                                    activeClass="nav__item"
                                >
                                    Contact
                                </ScrollLink> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
    
                <div className="navigation">
                    <input
                        type="checkbox"
                        className="navigation__checkbox"
                        id="navi-toggle"
                    />
    
                    <label htmlFor="navi-toggle" className="navigation__button">
                        <span className="navigation__icon">&nbsp;</span>
                    </label>
    
                    <div className="navigation__background">&nbsp;</div>
    
                    <Image className="menu-logo" src={logo} fluid={true} />
                    <nav className="navigation__nav">
                        <ul className="navigation__list">
                            <li className="navigation__item">
                                <Link className="navigation__link" to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link
                                    className="navigation__link"
                                    to="/DigitalTransformation"
                                >
                                    Digital Transformation
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link
                                    className="navigation__link"
                                    to="/Infrastructure"
                                >
                                    Infrastructure
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link className="navigation__link" to="/Security">
                                    Security
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link className="navigation__link" to="/Store">
                                    Store
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link className="navigation__link" to="/blog">
                                    Blog
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link className="navigation__link" to="/About">
                                    About
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link className="navigation__link" to="/Contact">
                                    Contact
                                </Link>
                                {/* <ScrollLink
                                    to="contact"
                                    smooth={true}
                                    duration={1000}
                                    className="navigation__link"
                                    activeClass="navigation__link"
                                >
                                    Contact
                                </ScrollLink> */}
                            </li>
                        </ul>
                    </nav>
                </div>
            </>
        );
    }
}